<template>
 <div class="form-group">
  <select :class="[selectClass, isHome ? 'home-select': '']" v-model="destination" @change="changeSelectedDestination">
    <option value="" selected>Wohin soll deine Reise gehen?</option>
    <option v-for="destination in destinations" :value="destination">{{destination.name}}</option>
  </select>
  <span class="material-icons right">gps_fixed</span>
</div>
</template>

<script>
  export default {
    name: 'DestinationSelect',
    data: () => ({
      destination: null
    }),
    props: {
      selectClass: {
        type: String,
        required: false,
      default: "form-control border input-rounded icon-right"
      },
      isHome: {
        type: Boolean,
      default: false,
      }
    },
    created() {
      /*check if there is a destination set in the store*/
      if(this.selectedDestination!==null){
        this.destination = this.selectedDestination;
      }
    },
    computed:{
      destinations(){
        return this.$store.getters.getDestinations;
      },
      selectedDestination(){
        return this.$store.getters.getSelectedDestination;
      },
    },
    methods: {
      changeSelectedDestination(){
        if(this.destination !== "" && this.destination !== null){
        //console.log(this.destination);
          this.$store.commit('SET_SELECTED_DESTINATION',this.destination);
          this.$emit('changedSelectedDestination',this.destination);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  .home-select {
    @media (min-width: 992px){
      height: 50px !important;
      font-size: 14px !important;
    }
  }
</style>
